import request from '@/plugins/axios'
export const apiFeedBackLists = (params: any) =>
    request.get('/feed_back.FeedBack/getPageList', {params})

//问题类型新增
export const apiUnitAdd = (data: any) =>
  request.post('/feed_back.FeedBackLevel/createLevel', data)

// 问题类型列表
export const apiUnitLists = (params: any) =>
  request.get('/feed_back.FeedBackLevel/getPage', {params})

// 问题类型删除
export const apiUnitDel = (params: any) =>
  request.post('/feed_back.FeedBackLevel/del', params)

// 问题类型编辑
export const apiUnitEdit = (data: any) =>
  request.post('/feed_back.FeedBackLevel/createLevel', data)

//用户反馈类型
export const apiUnitConfig = ()=>
  request.get('/feed_back.FeedBackLevel/getLevelList')

//处理用户反馈详情保存
export const apiFeedBackConfig = (data:any)=>
  request.post('/feed_back.FeedBack/saveData',data)

export const apiFeedBackProcess = (data:number)=>
  request.get('/feed_back.FeedBack/processInfo?pid='+data)

export const apiFeedBackDel = (data:any)=>
  request.post('/feed_back.FeedBack/del',data)
