

















































import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import AddUnit from '@/components/goods/add-brand.vue'
import { apiUnitDel, apiUnitLists } from '@/api/feedback'
import { RequestPaging } from '@/utils/util'
import GoodsSelect from '@/components/goods-select/index.vue'
import ExportData from '@/components/export-data/index.vue'
  @Component({
    components: {
      LsDialog,
      LsPagination,
      AddUnit,
      GoodsSelect,
      ExportData
    }
  })
export default class Unit extends Vue {
    $refs!: { addUnit: any }
    pager = new RequestPaging()
    queryObj = {
      name: ''
    }

    form: any = {
      name: '',
      sort: ''
    }

    delAr = []
    apiUnitLists = apiUnitLists

    handleAdd () {
      this.form = {
        name: '',
        sort: ''
      }
      this.$refs.addUnit.openDialog()
    }

    handleEdit ({ id, type_name, descript }: any) {
      this.form = {
        id,
        type_name,
        descript
      }
      this.$refs.addUnit.openDialog()
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-empty-function
    handleDel () {
      // 批量删除获取所有选中的数据
      // eslint-disable-next-line eqeqeq
      if (this.delAr.length == 0) {
        this.$message({
          type: 'error',
          message: '未选中删除数据!'
        })
      }
      // 开始遍历
      var data = []
      for (var i = 0, len = this.delAr.length; i < len; i++) {
        for (const key in this.delAr[i]) {
          // eslint-disable-next-line eqeqeq
          if (key == 'id') {
            data.push(this.delAr[i][key])
          }
        }
      }
      const res = {
        data: data
      }

      apiUnitDel(res).then(() => {
        this.getList()
      })
    }

    handleSelectionChange (val: any) {
      this.delAr = val
    }

    handleDelete (id: number) {
      // 删除更换为 批量
      const data = {
        data: [id]
      } // 更换为批量删除
      apiUnitDel(data).then(() => {
        this.getList()
      })
    }

    handleReset () {
      this.queryObj = {
        name: ''
      }
      this.getList()
    }

    getList () {
      this.pager.request({
        callback: apiUnitLists,
        params: this.queryObj
      })
    }

    created () {
      this.getList()
    }
}
